<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-form ref="selectForm" v-model="selectForm" class="select-form">
          <div>
            <el-form-item label="纳税税种">
              <el-select v-model.trim="selectForm.taxCategory" placeholder="请输入纳税税种">
                <el-option
                  v-for="item in taxCategoryList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="模板名称">
              <el-input v-model.trim="selectForm.tmplName" placeholder="请输入模板名称"
                        maxlength="200" @keyup.enter.native="submitForm()"/>
            </el-form-item>
          </div>
        </el-form>
        <div class="select-button">
          <select-button @select="handleSelect" @reset="handleReset"></select-button>
        </div>
      </div>
    </div>
    <div class="content-main">
      <div class="content-title">
        <span>底稿模板列表</span>
        <el-button type="primary" @click="handleAddNewTmplDialog">新增模板</el-button>
      </div>
      <div class="content-table">
        <div class="content-table">
          <el-table
            :data="tableData"
            stripe
            border
            v-loading="tableLoading"
            :header-cell-style="handleHeaderCellStyle"
            style="width: 100%">
            <el-table-column type="index" label="序号" width="60"></el-table-column>
            <el-table-column prop="taxCategory" label="纳税税种" width="180"
                             :formatter="taxCategoryFormat"></el-table-column>
            <el-table-column prop="tmplName" label="模板名称" min-width="180"></el-table-column>
            <el-table-column prop="status" label="启用状态" width="180">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  @change="handleDisabledChange(scope.row)"
                  active-value="0"
                  inactive-value="1"
                  active-color="#1890FF"
                  inactive-color="#F5222D">
                </el-switch>
              </template>
            </el-table-column>
            <!--<el-table-column prop="operate" label="操作" fixed="right" width="180">-->
            <!--<template slot-scope="scope">-->
            <!--<el-button type="text" @click="handleDetail(scope.row)">详情</el-button>-->
            <!--<el-button type="text" @click="handleDetail(scope.row)">导出</el-button>-->
            <!--</template>-->
            <!--</el-table-column>-->
          </el-table>
        </div>
        <div class="content-page">
          <el-pagination background
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page.sync="selectForm.page"
                         :page-sizes="pageSizes"
                         :page-size="selectForm.size"
                         layout="total, sizes, prev, pager, next"
                         :total="page.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog append-to-body
      :visible="addTmplDialogVisible"
      width="500px"
      title="新增模板"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="handleCancelAddTmplDialog"
    >
      <div class="dialog-wrap">
        <div class="dialog-form" v-loading="dialogLoading">
          <el-form class="form-item">
            <label>纳税税种</label>
            <el-form-item>
              <el-select v-model="addTmplForm.taxCategory" placeholder="请选择">
                <el-option v-for="item in declareTaxCategoryList" :key="item.value" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-form class="form-item">
            <label>模板名称</label>
            <el-form-item>
              <el-input v-model="addTmplForm.tmplName" placeholder="请输入模板名称" maxlength="50"></el-input>
            </el-form-item>
          </el-form>
          <el-form class="form-item-last">
            <label>申报地区</label>
            <el-form-item class="item-last">
              <el-select v-model="addTmplForm.regionCodes[0]" @change="handleChangeProvince"
                         placeholder="省/直辖市">
                <el-option v-for="item in provinceList" :key="item.code" :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
              <el-select v-model="addTmplForm.regionCodes[1]" @change="handleChangeCity" class="link-item"
                         placeholder="市/自治州">
                <el-option v-for="item in cityList" :key="item.code" :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
              <el-select v-model="addTmplForm.regionCodes[2]" class="link-item" placeholder="区/县/自治县">
                <el-option v-for="item in countyList" :key="item.code" :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="dialog-footer">
          <el-button type="default" @click="handleCancelAddTmplDialog">取消</el-button>
          <el-button type="primary" @click="handleAddTmpl" :disabled="validateAddButton">添加</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  tmplTntList,
  saveDeclareTntDeclareTmpl,
  changeStatus
} from '@/service/declare/index'
import {
  getSystemRegion,
  getSystemRegionCity,
  getSystemRegionCounty
} from '@/service/system/region'

import { tableStyle, pagingMethods, dateOption, changeMethods } from '@/util/mixins'
import { FmtEnum } from '@/util/enumUtil'

export default {
  name: 'tntDataTmpl',
  mixins: [tableStyle],
  data () {
    return {
      selectForm: {
        taxCategory: undefined,
        tmplName: undefined,
        page: 1,
        size: 15
      },
      page: {
        total: 0
      },
      addTmplForm: {
        taxCategory: undefined,
        tmplName: undefined,
        regionCodes: [],
      },
      pageSizes: [15, 30, 50, 100],
      addTmplDialogVisible: false,
      tableData: [],
      tableLoading: false,
      taxCategoryList: [],
      declareTaxCategoryList: [],
      provinceList: [],
      cityList: [],
      countyList: [],
      declareTmplEnabled: [],
      dialogLoading: false,
    }
  },
  created () {
    this.initDict()
    this.submitForm()
  },
  computed: {
    validateAddButton () {
      let form = this.addTmplForm
      return form.taxCategory == undefined
        || form.tmplName == undefined
        || form.regionCodes[0] == null
        || form.regionCodes[0] == undefined
    }
  },
  methods: {
    initDict () {
      this.getDicts('dec_tax_category').then(res => {
        this.taxCategoryList = [{ label: '全部', value: undefined }, ...res.data]
        this.declareTaxCategoryList = [...res.data]
      })
      this.getDicts('sys_normal_disable').then(res => {
        this.declareTmplEnabled = res.data
      })
    },
    handleSelect () {
      this.submitForm()
    },
    //切换分页条数
    handleSizeChange (value) {
      this.selectForm.size = value
      this.submitForm()
    },
    //翻页
    handleCurrentChange (value) {
      this.selectForm.page = value
      this.submitForm()
    },
    submitForm () {
      this.tableLoading = true
      tmplTntList(this.selectForm).then(res => {
        this.tableLoading = false
        if (res.success) {
          this.tableData = res.data.records
          this.page = res.data
        }
      })
    },
    /* 详情 */
    handleDetail (row) {

    },
    //重置搜索条件
    handleReset () {
      this.selectForm = {
        taxCategory: undefined,
        tmplName: undefined,
        page: 1,
        size: 15
      }
    },
    //重置添加条件
    resetAddForm () {
      this.addTmplForm = {
        taxCategory: undefined,
        tmplName: undefined,
        regionCodes: [],
      }
      this.provinceList = []
      this.cityList = []
      this.countyList = []
    },
    //添加底稿模板弹窗
    handleAddNewTmplDialog () {
      getSystemRegion().then(res => {
        if (res.success) {
          this.provinceList = res.data
        }
      })
      this.addTmplDialogVisible = true
    },
    //取消新增模板弹窗
    handleCancelAddTmplDialog () {
      this.resetAddForm()
      this.addTmplDialogVisible = false
    },
    //添加模板
    handleAddTmpl () {
      let result = this.validateAddForm()
      if (!result) {
        return
      }
      this.dialogLoading = true
      saveDeclareTntDeclareTmpl(this.addTmplForm).then(res => {
        this.dialogLoading = false
        if (res.success) {
          this.handleSelect()
          this.handleCancelAddTmplDialog()
          this.toast('底稿模板添加成功！', 'success')
        }
      })
    },
    //验证新增模板参数
    validateAddForm () {
      let form = this.addTmplForm
      if (form.taxCategory == '' || form.taxCategory == null || form.taxCategory == undefined) {
        this.toast('请选择纳税种类', 'warning')
        return false
      }
      if (form.tmplName == '' || form.tmplName == null || form.tmplName == undefined) {
        this.toast('模板名称不能为空', 'warning')
        return false
      }
      if (form.regionCodes[0] == undefined || form.regionCodes[0] == null) {
        this.toast('请选择申报地区', 'warning')
        return false
      }
      return true
    },
    //禁用状态
    handleDisabledChange (row) {
      changeStatus({ 'id': row.id, 'status': row.status }).then((res) => {
        this.toast(res.message, 'success')
      })
    },
    //选择省份 联动
    handleChangeProvince () {
      //更改省份时，重置市区
      this.addTmplForm.regionCodes.splice(1, 2)
      getSystemRegionCity(this.addTmplForm.regionCodes[0]).then(res => {
        if (res.success) {
          this.cityList = res.data
        }
      })
    },
    //选择城市 联动
    handleChangeCity () {
      //更改市时，重置区
      this.addTmplForm.regionCodes.splice(2, 1)
      getSystemRegionCounty(this.addTmplForm.regionCodes[1]).then(res => {
        if (res.success) {
          this.countyList = res.data
        }
      })
    },
    //纳税税种格式化
    taxCategoryFormat (row) {
      return this.getDictLabel(this.declareTaxCategoryList, row.taxCategory)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/style/select.scss";

  .select-content {
    .select-form {
      width: 55%;
    }

    .select-button {
      flex: none;
    }
  }

  .content-main {
    .content-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      span {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: 22px;
      }
    }

    .content-table {
      ::v-deep .el-button {
        padding: 0;
      }
    }
  }

  .dialog-wrap {
    text-align: left;

    .dialog-form {
      margin: -24px 24px 0;

      label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
      }

      .form-item {
        margin-top: 12px;
      }

      .form-item-last {
        margin-top: 12px;

        .item-last .link-item {
          margin-left: 15px;
        }

        ::v-deep .el-input {
          width: 140px;
        }
      }

      ::v-deep .el-input {
        width: 560px;
      }
    }

    .dialog-footer {
      margin: 24px;
      padding-bottom: 24px;
      text-align: right;
    }

    label:before {
      content: "*";
      color: #F5222D;
      margin-right: 2px;
    }
  }

  ::v-deep .dialog-wrap .form-item .el-input {
    width: 452px;
  }
</style>
